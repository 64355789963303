<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 批号修改</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="产品批号：" prop="batch_number">
            <el-input v-model="form.batch_number" placeholder="请输入产品批号" style="width:300px" />
        </el-form-item>
        <el-form-item label="有效期至：" prop="valid_until">
            <el-date-picker style="width:300px"
              v-model="form.valid_until"
              type="date"
              value-format="YYYY-MM-DD" :shortcuts="shortcuts"
              placeholder="选择日期(长期不选)"
            >
            </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addGoods",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
          
      },
      rules: {
        batch_number: [
          { required: true, message: "产品批号不能为空",trigger: "blur"}
        ]
      },
      shortcuts: [{
        text: '2099-12-31',
        value:'2099-12-31'
      }, {
        text: '长效期',
        value:'null'
      }]
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }
  },
  methods: {
      // 表单重置
      reset(pid) {
        this.form = {
          
        };
        if(this.$refs['form']){
          this.$refs['form'].clearValidate();
        }
      },
      /** 提交按钮 */
      submitForm: function(form) {
        this.$refs[form].validate((valid)  => {
          if (valid) {
            this.is_ajax=true;
            if(form=='form'){
              let _data={...this.form};
              _data.valid_until=_data.valid_until=='Invalid Date'?null:(_data.valid_until || null);
              this.$httpPost("/backend/Warehouse/batchNumber", _data).then((res) => {
                  if (res.status == 200) {
                      if(this.form.id){
                          this.$message.success('更新成功');
                          this.$router.go(-2)
                      }else{
                          this.$message.success('新增成功');
                          this.reset();
                      }
                  } else {
                      this.$message.error(res.message);
                  }
                  this.is_ajax=false;
              }).catch((err) => {
                  console.log(err);
                  this.is_ajax=false;
              });
            }
          }
        });
      },
      get_data(){
          this.$httpGet("/backend/Warehouse/batchRead", {id:this.id}).then((res) => {
              if (res.status == 200) {
                  this.form=res.data
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
  }
};
</script>

<style lang="scss" scoped></style>
